export const environment = {
  env: 'stg',
  production: true,
  urlBase: 'https://entre.staging.filazero.app/',
  urlExterna: 'https://site.staging.filazero.net/',
  urlApi: 'https://api.staging.filazero.net/',
  urlMeet: 'https://meetdev.filazero.net/',
  feedBackSiteUrl: 'https://site.staging.filazero.net/',
  cloudFunctionsUrl: 'https://us-central1-filazero-6db8c.cloudfunctions.net/',
  recaptchaKey: '6LfccPMpAAAAAIEhlhoMJsdxym18sXbzRZ4AI9bs',
  pusher: {
    key: '0041ab94e110de594bdb',
    cluster: 'mt1',
    app_id: '75465'
  },
  artesanoProviderId: 3328,
  boticarioProviderId: 352,
  phoneNoelProviderId: 357,
  queueNoelProviderId: 776,
  firebase: {
    apiKey: 'AIzaSyAaV9u67AL1nczlia-VJU-gmiR8rWpRIdE',
    authDomain: 'filazero-6db8c.firebaseapp.com',
    databaseURL: 'https://fz-staging.firebaseio.com',
    projectId: 'filazero-6db8c',
    storageBucket: 'filazero-6db8c.appspot.com',
    messagingSenderId: '747494649248',
    appId: '1:747494649248:web:9e427106a978765e0888c5',
    measurementId: 'G-XQFEP5KGCE'
  },
  nike: {
    providerId: 3304,
    nikeStore: {
      '28c3f1b63d1f4e6a96d1992deea26140': 'nike'

    }
  },
  operatorId: 'bb16fe53-beda-4de0-9046-222fb3393099',
  smartCodePrefix: 'SC',
};
